// https://www.freakyjolly.com/google-signin-login-button-in-react-js-example-using-react-google_login-package/

import axios from "axios";
import React, { useState, useEffect } from "react";
import { tokenExpired } from './token.js';
import { baseURL } from './config.js';
import './App.css';

function App() {
    useEffect(() => {
        handleTokenFromQueryParams();
    }, []);

    const serverURL = baseURL();

    const createGoogleAuthLink = async () => {
        try {
            const url = serverURL + '/api/google/auth';
            const response = await axios.get(url);
            window.location.href = response.data.url;
        } catch (err) {
            throw new Error('App.js - could not get auth link', err.message);
        }
    };

    const handleTokenFromQueryParams = () => {
        const query = new URLSearchParams(window.location.search);
        for (var key of query.keys()) {
            if (key === 'code') {
                const url = serverURL + '/api/google/redirect' + window.location.search;
                axios.get(url)
                    .then(response => {
                        const accessToken = response.data.access_token;
                        const refreshToken = response.data.refresh_token;
                        const expirationDate = response.data.expiry_date;
                        if (accessToken && refreshToken) {
                            storeTokenData(accessToken, refreshToken, expirationDate);
                        }

                        window.location.href = serverURL;
                    });
            }
        }
    };

    const storeTokenData = async (token, refreshToken, expirationDate) => {
        sessionStorage.setItem('accessToken', token);
        sessionStorage.setItem('refreshToken', refreshToken);
        sessionStorage.setItem('expirationDate', expirationDate);
    };

    const signOut = () => {
        sessionStorage.clear();
        window.location.href = serverURL
    };

    const openHealthAutoExport = () => {
        const accessToken = sessionStorage.getItem('accessToken');
        const refreshToken = sessionStorage.getItem('refreshToken');
        const expDate = sessionStorage.getItem('expirationDate');

        const dateString = new Date(expDate);

        const haeURL = 'com.HealthExport://google';

        const url = `${haeURL}?accessToken=${accessToken}&refreshToken=${refreshToken}&expirationDate=${dateString}`;
        window.location.href = url;
    };

    return (
      <div className="App">
        <h1 className="App-header">Health Auto Export</h1>

        <div>
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/hae.png`}
            className="App-logo"
          ></img>
        </div>

        <div className="App-description">
          <p>
            <strong>Connect to Google Drive</strong>
            <br />
            Allow Health Auto Export to access your Google account in order to
            sync data directly to your Google Drive. <br />
            Health Auto Export only uses this login process to get the required
            access tokens to upload data to your Google Drive.
          </p>

          <p>
            <strong>Privacy:</strong>
            <br />
            Health Auto Export safeguards your privacy in the following ways:
            <ul>
              <li>
                does not collect any personally identifiable information about
                you (e.g. name, email address, location, etc)
              </li>
              <li>
                does not read the contents of your Google Drive except folders
                and files the app creates itself
              </li>
              <li>uploads files directly to your Google Drive over HTTPS</li>
            </ul>
          </p>
        </div>

        <div>
          {tokenExpired() ? (
            // <div className="Drive-connect">
            //   <button className="Btn Action-btn" onClick={createGoogleAuthLink}>
            //     Connect Google Drive
            //   </button>
            //   <img
            //     src={`${process.env.PUBLIC_URL}/assets/images/forward-arrow.png`}
            //     className="Forward-arrow"
            //   ></img>
            //   <img
            //     src={`${process.env.PUBLIC_URL}/assets/images/drive.png`}
            //     className="Drive-logo"
            //   ></img>
            // </div>
            <div>
              <a href="api.healthyapps.dev">Connect Using HealthyApps</a>
            </div>
          ) : (
            <>
              <button className="Btn Action-btn" onClick={openHealthAutoExport}>
                Open Health Auto Export
              </button>
              <button className="Btn Neutral-btn" onClick={signOut}>
                Cancel
              </button>
            </>
          )}
        </div>
      </div>
    );
}

export default App;