'use strict';

const baseURL = () => {
    switch (process.env.NODE_ENV) {
        case 'dev':
            return 'http://localhost:3001';
        case 'test':
            return 'https://api.lybron.dev';
        case 'prod':
            return 'https://api.healthyapps.dev';
        default:
            return 'https://api.lybron.dev';
    }
};

export { baseURL };